<template>
  <div>
    <div class="user-posting">
        <b-row>
          <b-col sm="12"><h5>Create your Performer Portfolio</h5></b-col>
        </b-row>    
        <b-row>
          <b-col sm="8 mt-2"><p>Publish your Artist bio-data with complete contact details and a profile picture. </p></b-col>
          <b-col sm="4 mt-2"><b-button pill type="submit" variant="warning"><router-link :to="{name:'performers',params:{ Maincatg:'All'}}">View All Performers</router-link></b-button></b-col>

        </b-row>
    </div>    
    <b-col cols="12 my-4">      
              <b-form  @submit="onSubmit" @reset="onReset" v-if="show">
                <b-form-group id="input-group-2" label-for="profile_name" class="col-8">
                  <b-form-input
                    id="profile_name"
                    v-model="InpForm.profile_name"
                    placeholder="Performer Name"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group id="input-group-2" label-for="profile_title" class="col-8">
                  <b-form-input
                    id="profile_title"
                    v-model="InpForm.profile_title"
                    placeholder="Performer Title"
                    required
                  ></b-form-input>                
                </b-form-group>

                <b-form-group id="input-ProfType" label-for="ProfType" class="col-8">
                  <b-form-select 
                    v-model="InpForm.ProfType" 
                    :options="ListProfType">
                  </b-form-select>
                </b-form-group>                  

                <RefFormCatg :ShowMainCatg="true" :ShowMultpleCatg="true" @EmitMainCatg="SetMainCatg" @EmitMultpleCatg="SetMultpleCatg" :key="ComponentKey +1"/>

                <b-form-group class="col-8">
                <b-form-textarea
                  id="Address"
                  size="md"
                  v-model="InpForm.profile_address"
                  placeholder="Performer Address"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>   
                </b-form-group>               

                <RefFormPlaces :ShowCountry="true" @EmitCountry="SetCountry" @EmitState="SetState" @EmitCity="SetCity" :key="ComponentKey +2"/>
                <!-- <b-form inline class="col-12">
                <b-form-group id="input-group-2" label-for="prof_country">
                  <b-form-input
                    id="prof_country"
                    v-model="InpForm.prof_country"
                    placeholder="Country"
                    required
                  ></b-form-input>                
                </b-form-group>

                <b-form-group id="input-group-2" label-for="prof_state" >
                  <b-form-input
                    id="prof_state"
                    v-model="InpForm.prof_state"
                    placeholder="State"
                    required
                  ></b-form-input>                
                </b-form-group>

                <b-form-group id="input-group-2" label-for="prof_city">
                  <b-form-input
                    id="prof_city"
                    v-model="InpForm.prof_city"
                    placeholder=" City"
                    required
                  ></b-form-input>                
                </b-form-group>
              </b-form> -->

              <b-form inline class="col-12 mt-2">
                <b-form-group id="input-group-2" label-for="profile_cntct1">
                  <b-form-input
                    id="profile_cntct1"
                    v-model="InpForm.profile_cntct1"
                    placeholder="Contact no #1"
                  ></b-form-input>                
                </b-form-group>

                <b-form-group id="input-group-2" label-for="profile_cntct2">
                  <b-form-input
                    id="profile_cntct2"
                    v-model="InpForm.profile_cntct2"
                    placeholder="Contact no #2"
                  ></b-form-input>                
                </b-form-group>
              </b-form>              

                <b-form inline class="col-12 mt-2">
                <b-form-group id="input-group-2" label-for="profile_email">
                  <b-form-input
                    id="profile_email"
                    v-model="InpForm.profile_email"
                    @change="FindNewUserEmailID()"
                    placeholder="Your Email ID"
                    required 
                  ></b-form-input>                
                </b-form-group>

                <b-form-group id="input-group-2" label-for="profile_website" >
                  <b-form-input
                    id="profile_website"
                    v-model="InpForm.profile_website"
                    placeholder="Your Website link"
                    required
                  ></b-form-input>                
                </b-form-group>

                <b-form-group id="input-group-2" label-for="profile_facebook">
                  <b-form-input
                    id="profile_facebook"
                    v-model="InpForm.profile_facebook"
                    placeholder="Your Facebook link"
                    required
                  ></b-form-input>                
                </b-form-group>
              </b-form>              

              <b-form inline class="col-12 mt-2">
                <b-form-group id="input-group-2" label-for="profile_instagram">
                  <b-form-input
                    id="profile_instagram"
                    v-model="InpForm.profile_instagram"
                    placeholder="Your Instagram link"
                  ></b-form-input>
                </b-form-group>

                <b-form-group id="input-group-2" label-for="profile_youtube">
                  <b-form-input
                    id="profile_youtube"
                    v-model="InpForm.profile_youtube"
                    placeholder="Your Youtube link"
                  ></b-form-input>                
                </b-form-group>

                <b-form-group id="input-group-2" label-for="profile_twitter">
                  <b-form-input
                    id="profile_twitter"
                    v-model="InpForm.profile_twitter"
                    placeholder="Your Twitter link"
                  ></b-form-input>                
                </b-form-group>                
              </b-form>

                <b-form-group class="col-8 mt-2">
                <b-form-textarea
                  id="profile_description"
                  size="md"
                  v-model="InpForm.profile_description"
                  placeholder="Performer Details"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>              
                </b-form-group>

                <b-form-group class="col-8 mt-2">
                    <b-form-file
                      v-model="file1"
                      :state="Boolean(file1)"
                      placeholder="Choose a cover picture..."
                      drop-placeholder="or Drop file here..."
                      @change="previewImage" accept="image/*" 
                    ></b-form-file>
                </b-form-group>                

                <!-- <b-row class="my-3">                  
                  <b-col cols="12">         
                    <b-button class="float-left ml-3" variant="success"  @click="click1">{{ButtonText}}
                    </b-button>
                    <input type="file" ref="input1"
                      style="display: none"
                      @change="previewImage" accept="image/*" >                                        
                  </b-col>                  
                </b-row>

                <b-row>
                  <b-col cols="12 my-3 ml-3" >                    
                    <b-img v-if="ShowPhoto" class="float-left" rounded height="80" width="80" :src="PhotoPreview" >                 
                    </b-img>
                      <b-spinner v-else-if="ShowSpinner" small
                        v-for="variant in variants"
                        :variant="variant"
                        :key="variant"
                      ></b-spinner>                
                  </b-col>  
                </b-row>                -->

                <!-- <b-form-group class="col-8 mt-2">
                <b-form-textarea
                  id="profile_description"
                  size="md"
                  v-model="InpForm.profile_description"
                  placeholder="Description"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea> -->

                <!-- Detail Section starts here >> users_profile_detail -->
                <!-- <b-form-textarea
                  id="profile_programs"
                  size="md"
                  v-model="InpForm.profile_programs"
                  placeholder="Programs"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>

                <b-form-textarea
                  id="profile_trainings"
                  size="md"
                  v-model="InpForm.profile_trainings"
                  placeholder="Trainings"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>

                <b-form-textarea
                  id="profile_participations"
                  size="md"
                  v-model="InpForm.profile_participations"
                  placeholder="Participations"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>

                <b-form-textarea
                  id="profile_awards"
                  size="md"
                  v-model="InpForm.profile_awards"
                  placeholder="Awards"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>                                               
                </b-form-group>                   

                <b-form-checkbox class="col-8 mt-2"
                  id="checkbox-1"
                  v-model="InpForm.premium_ind"
                  name="checkbox-1"
                  value="Y"
                  unchecked-value="N"
                >
                  Premium Profile?
                </b-form-checkbox>      -->

                <!-- Media Section starts here >> users_profile_media -->
                 <!-- <b-form-group  id="input-group-2" label-for="" class="col-8 mt-2">
                  <b-form-input
                    id="Video1"
                    v-model="InpForm.Video1"
                    placeholder="Video1"
                  ></b-form-input>
                  <b-form-input
                    id="Video2"
                    v-model="InpForm.Video2"
                    placeholder="Video2"
                  ></b-form-input>
                  <b-form-input
                    id="Brochure"
                    v-model="InpForm.Brochure"
                    placeholder="Brochure"
                  ></b-form-input>
                  <b-form-input
                    id="Biodata"
                    v-model="InpForm.Biodata"
                    placeholder="Biodata"
                  ></b-form-input>
                </b-form-group>               -->

                <b-button pill type="submit" variant="primary">Submit</b-button>
                <b-button pill type="reset" variant="danger">Cancel</b-button>
              </b-form>        
    </b-col>
    <b-modal v-model="modalShow" @ok="SendLoginLink"><b-alert variant="success" show>Your email id {{RespEmailID}} is already linked with a profile. Click OK to receive a login link.</b-alert></b-modal>
    <b-modal v-model="ModalRegDone" @ok="NextRouteLogin"><b-alert variant="success" show>Success! Check your Email and follow the instructions.</b-alert></b-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import Vueaxios from 'vue-axios'
import axios from 'axios'
import {fb} from '../firebase'
import { mapGetters } from "vuex";


Vue.use(Vueaxios,axios)
  export default {
    data() {
      return {
      // Input form variables
      InpForm: {
      profile_name: '',
      profile_title: '',
      ProfType: 'default',
      profile_address: '',
      profile_cntct1: '',
      profile_cntct2: '',
      profile_email: '',
      profile_website: '',
      profile_facebook: '',
      profile_instagram: '',
      profile_youtube: '',
      profile_twitter: '',
      profile_description: '',
      profile_programs: '',
      profile_trainings: '',  
      profile_participations: '',  
      profile_awards: '',   
      premium_ind: 'N',  
      Video1: '',   
      Video2: '',   
      Brochure: '',   
      Biodata: '',   
      },
      ComponentKey: 0,
      ChildMainCatg: '',
      ChildMultpleCatg: [],
      ChildCountry: '',
      ChildState: '',
      ChildCity: '',
        RespUserEmail: null,
        RespEmailID: null,
        RespName: null,
        modalShow: false,   
        ModalRegDone: false,   
      // Firebase image upload variables
      PhotoPreview: null,
      PhotoUrl: 'https://storage.googleapis.com/thiramedia/artists/perfomr',
      imageData: null, 
      file1: null,
      // Response array variables
      RespIns: [],
      RespGetMaxID: [ {tbl_next_seq: '0'} ],
      // Select drop down options
      ListProfType: [
        { value: 'default', text: 'Select Profile Type' },
        { value: 'Performer', text: 'Artist - Performing Arts'},
        { value: 'Painter', text: 'Artist - Paintings'},
        { value: 'Poet', text: 'Artist - Poet'},
        { value: 'Actor', text: 'Artist - Actor'},
      ],         
      // Other variables
      show: true,
      NextSeqNo: '',
      ShowSpinner: false,
      ShowPhoto: false,
      variants: ['primary', 'danger', 'warning'],
      ButtonText: 'Upload cover photo',
      }
    },
    mounted()
    {
    },    
    methods: {
      onSubmit(event) {
        event.preventDefault()
        this.GetMaxID()
      },

        SetMainCatg(event) {
            this.ChildMainCatg = event
            // console.log('ChildMainCatg',this.ChildMainCatg)
        },
        
       SetMultpleCatg(event) {
           this.ChildMultpleCatg = event
          // console.log('ChildMultpleCatg1',this.ChildMultpleCatg[0])
          // console.log('ChildMultpleCatg2',this.ChildMultpleCatg[1])
          // console.log('ChildMultpleCatg3',this.ChildMultpleCatg[2])
          // console.log('ChildMultpleCatg4',this.ChildMultpleCatg[3])
          // console.log('ChildMultpleCatg5',this.ChildMultpleCatg[4])
       },        

        SetCountry(event) {
            this.ChildCountry = event
            console.log('ChildCountry',this.ChildCountry)
        },

        SetState(event) {
            this.ChildState = event
            console.log('ChildState',this.ChildState)
        },

        SetCity(event) {
            this.ChildCity = event
            console.log('ChildCity',this.ChildCity)
        }, 
        
      SendLoginLink(){
        this.SignInLinkToEmail(this.RespEmailID);
      },

      NextRouteLogin() {
        this.$router.replace('/')
      },      
      
      SignInLinkToEmail(InpUserEmail1) {
      var actionCodeSettings = {
        // URL you want to redirect back to. The domain (www.example.com) for this
        // URL must be in the authorized domains list in the Firebase Console.
        url: 'https://thiraseela.com/user/confirm-login',
        // url: 'https://thiraseela.com/user/SetUserPswd', 
        // This must be true.
        handleCodeInApp: true,
      };        
        fb.auth().sendSignInLinkToEmail(InpUserEmail1, actionCodeSettings)
        .then(() => {
          // The link was successfully sent. Inform the user.
          // Save the email locally so you don't need to ask the user for it again
          // if they open the link on the same device.
          window.localStorage.setItem('emailForSignIn', InpUserEmail1);
          this.ModalRegDone=true
        })
        .catch(() => {
          console.log('Error!!')
        });
      },      

      GetMaxID(){
        const REQformData = new FormData();
        REQformData.append('table_name', 'users_profile_main');          
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=GetMaxID',REQformData)
        .then((resp)=>{
        this.RespGetMaxID=resp.data.GetMaxID;
        const FirstObject = this.RespGetMaxID[0];
        this.NextSeqNo = FirstObject.tbl_next_seq;
        // console.log('MaxSeqNo',this.NextSeqNo)
        this.InsUserProfile()
        this.onUpload()
      })
      },   
          
      InsUserProfile(){
        this.ComponentKey=0;
        const REQformData = new FormData();
        REQformData.append('seq_no', this.NextSeqNo);
        REQformData.append('profile_name', this.InpForm.profile_name);
        REQformData.append('profile_title', this.InpForm.profile_title);
        REQformData.append('profile_type', this.InpForm.ProfType);
        REQformData.append('catg_main', this.ChildMainCatg);
        REQformData.append('profile_address', this.InpForm.profile_address);
        REQformData.append('prof_country', this.ChildCountry);
        REQformData.append('prof_state', this.ChildState);
        REQformData.append('prof_city', this.ChildCity);
        REQformData.append('profile_cntct1', this.InpForm.profile_cntct1);
        REQformData.append('profile_cntct2', this.InpForm.profile_cntct2);
        REQformData.append('profile_email', this.InpForm.profile_email);
        REQformData.append('profile_website', this.InpForm.profile_website);
        REQformData.append('profile_facebook', this.InpForm.profile_facebook);
        REQformData.append('profile_instagram', this.InpForm.profile_instagram);
        REQformData.append('profile_youtube', this.InpForm.profile_youtube);
        REQformData.append('profile_twitter', this.InpForm.profile_twitter);
        REQformData.append('profile_photo', this.PhotoUrl + this.NextSeqNo + '/image1.jpeg');
        REQformData.append('profile_description', this.InpForm.profile_description);
        REQformData.append('profile_programs', this.InpForm.profile_programs);
        REQformData.append('profile_trainings', this.InpForm.profile_trainings);
        REQformData.append('profile_participations', this.InpForm.profile_participations);
        REQformData.append('profile_awards', this.InpForm.profile_awards);
        REQformData.append('catg1', this.ChildMultpleCatg[0]);
        REQformData.append('catg2', this.ChildMultpleCatg[1]);
        REQformData.append('catg3', this.ChildMultpleCatg[2]);
        REQformData.append('catg4', this.ChildMultpleCatg[3]);
        REQformData.append('catg5', this.ChildMultpleCatg[4]);
        REQformData.append('premium_ind', this.InpForm.premium_ind);
        REQformData.append('Video1', this.InpForm.Video1);
        REQformData.append('Video2', this.InpForm.Video2);
        REQformData.append('Brochure', this.InpForm.Brochure);
        REQformData.append('Biodata', this.InpForm.Biodata);

        // Logic to assign profile owner
        if (this.user.authenticated) {REQformData.append('created_by', this.user.data.email)}
        else if (this.InpForm.profile_email != '') {REQformData.append('created_by', this.InpForm.profile_email)}
        else {{REQformData.append('created_by', 'info@thiraseela.com')}}

        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=InsUserProfile',REQformData)
        .then((resp)=>{
          this.RespIns=resp.data.message
          alert(this.RespIns)
          this.ClearForms()
          })
        .catch(error => {
          alert(error)
          })
      },

      FindNewUserEmailID() {
        const REQformData = new FormData();
        REQformData.append('email_id', this.InpForm.profile_email);          
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=FindNewUserEmailID',REQformData)
        .then((resp)=>{
        this.RespUserEmail=resp.data.FindNewUserEmailID;
        const FirstObject = resp.data.FindNewUserEmailID[0];
        this.RespEmailID = FirstObject.user_email;
        this.RespName = FirstObject.user_name;
        this.modalShow=true;
      })
      },

     ClearForms(){
       if (this.user.authenticated) {this.$router.replace('/user/dashboard')}

        this.InpForm.profile_name = '';
        this.InpForm.profile_title = '';
        this.InpForm.ProfType = 'default';
        this.InpForm.profile_address = '';
        this.InpForm.profile_cntct1 = '';
        this.InpForm.profile_cntct2 = '';
        this.InpForm.profile_email = '';
        this.InpForm.profile_website = '';
        this.InpForm.profile_facebook = '';
        this.InpForm.profile_instagram = '';
        this.InpForm.profile_youtube = '';
        this.InpForm.profile_twitter = '';
        this.InpForm.profile_description = '';
        this.InpForm.profile_programs = '';
        this.InpForm.profile_trainings = '';  
        this.InpForm.profile_participations = '';  
        this.InpForm.profile_awards = '';  
        this.InpForm.premium_ind = '';    
        this.InpForm.Video1 = '';   
        this.InpForm.Video2 = '';   
        this.InpForm.Brochure = '';   
        this.InpForm.Biodata = '';
        this.imageData=null;
        this.file1=null;
        // this.ShowSpinner=false;
        // this.ShowPhoto=false;
        // this.ButtonText='Upload cover photo';
        this.ComponentKey=1;
      },        
                 
      onReset(event) {
        event.preventDefault()
        this.ClearForms()
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
        this.show = true
        })
      },  

// Firebase image upload methods
      click1() {
        this.$refs.input1.click()   
      },
      
      previewImage(event) {
        // this.ShowSpinner=true;
        this.uploadValue=0;
        this.PhotoPreview=null;
        this.imageData = event.target.files[0];
        // this.ButtonText='Submit to upload...'
      },

      onUpload(){
        this.PhotoPreview=null;
        // const storageRef=fb.storage().ref(`${this.imageData.name}`).put(this.imageData);
        const storageRef=fb.storage().ref().child('artists/' + 'perfomr'+ this.NextSeqNo +'/' + 'image1.jpeg').put(this.imageData);
        storageRef.on(`state_changed`,snapshot=>{
        this.uploadValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
          }, error=>{console.log(error.message)},
        ()=>{this.uploadValue=100;
            storageRef.snapshot.ref.getDownloadURL().then(()=>{
                // this.ShowSpinner=false;
                // this.PhotoPreview =url;
                // this.ShowPhoto=true;
                // console.log(this.PhotoPreview)
              });
            }      
          );
       },
    },

  computed: {
    // map `this.user` to `this.$store.getters.user` 
    ...mapGetters({
      user: "user"
    })
    }     
  }
</script>

